<template>
  <div class="main-menu card">
    <div class="main-menu__item main-menu__item-inactive">
      New Campaign
    </div>
    <router-link
        to="/pvp"
        class="main-menu__item"
    >
      PVP
    </router-link>
    <div class="main-menu__item main-menu__item-inactive">
      Training Camp
    </div>
    <div class="main-menu__item main-menu__item-inactive">
      Options
    </div>
    <router-link
        to="/profile"
        class="main-menu__item"
    >
      Profile
    </router-link>
    <a
        href="https://shedever-studio.gitbook.io/chess-zombies-wpeng/"
        target="_blank"
        class="main-menu__item"
    >
      ChessZombie Wiki
    </a>
  </div>
</template>

<script>

export default {
  name: "MainMenu",
  mounted() {
    if (this.$store.state.auth.jwt) {
        this.axios.defaults.headers = {
          'Authorization': `bearer ${this.$store.state.auth.jwt}`
        };
      }
  },
  methods: {
    playMenu() {
      const menu = document.createElement('audio');
      menu.src = '/sounds/menu.mp3';
      menu.play();
    }
  }
}
</script>

<style scoped lang="scss">
  .main-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__item{
      position: relative;
      width: 200px;
      padding: 2em;
      background: url("../assets/button.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 1em;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      &:hover{
        filter: contrast(150%);
      }
      &-inactive{
        filter: sepia(1) !important;
        cursor: default;
      }
    }
  }

  @media (max-width: 720px) {
    .main-menu{
      width: 100%;
      box-sizing: border-box;
      margin: 1em;
      &__item{
        box-sizing: border-box;
      }
    }
  }
</style>

import {createRouter, createWebHistory} from "vue-router";

import MainMenu from "@/components/MainMenu";
import PagePVP from "@/components/PagePVP";
import PageProfile from "@/components/PageProfile";


const routes = [
  { path: '/', component: MainMenu },
  { path: '/pvp', component: PagePVP },
  { path: '/profile', component: PageProfile },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router

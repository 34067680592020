<template>
  <div class="card-landscape">
      <div class="pvp__headers">
        <div class="pvp__header">
          Game Mode
        </div>
      </div>
      <div class="pvp__choosers">
        <input type="radio" v-model="fraction" value="Zombie" id="race-zombie">
        <label class="pvp__radio" for="race-zombie">
          <img :src="zombie" alt="" width="32" height="32">
          Zombie
        </label>
        <input type="radio" v-model="mode" value="KillKingTimer" id="mode-kiktime">
        <label class="pvp__radio" for="mode-kiktime">
          Kill the king in 20 minutes
        </label>
        <input type="radio" v-model="fraction" value="Elf" id="race-elf">
        <label class="pvp__radio" for="race-elf">
          <img :src="elf" alt="" width="32" height="32">
          Elves
        </label>
      </div>
    <button class="pvp-start btn btn-submit" :onclick="startMatch">
      Start
    </button>
    <LeaderBoard />
  </div>
</template>

<script>

import Unity from "@/./unity.js"
import LeaderBoard from "@/components/LeaderBoard.vue";
import zombie from "../assets/zombie.png"
import elf from "../assets/elf.png"

export default {
  name: "PagePVP",
  data: function () {
    return {
      zombie: zombie,
      elf: elf
    }
  },
  components: {LeaderBoard},
  computed: {
    fraction: {
      get () {
        return this.$store.state.pvp.fraction
      },
      set (value) {
        this.$store.commit('SET_FRACTION', value)
      }
    },
    mode: {
      get () {
        return this.$store.state.pvp.mode
      },
      set (value) {
        this.$store.commit('SET_MODE', value)
      }
    },
    // hints: {
    //   get () {
    //     return this.$store.state.pvp.hints
    //   },
    //   set (value) {
    //     this.$store.commit('SET_HINTS', value)
    //   }
    // },
  },
  methods: {
    startMatch(){
      this.$store.dispatch('SAVE_CONFIG', this)
      this.$store.dispatch('SET_STATUS', 'play')
      let payload = {
        'token': this.$store.state.auth.jwt,
        'fraction': this.$store.state.pvp.fraction,
        'mode': this.$store.state.pvp.mode,
        // 'hints': this.$store.state.pvp.hints,
      }
      Unity.send('GameManager', 'SetToken', JSON.stringify(payload))
      Unity.on('created', () => {
        Unity.send('GameManager', 'SetToken', JSON.stringify(payload))
    })
    }
  }
}

</script>

<style scoped lang="scss">
  .pvp{
    display: grid;
    grid-template-columns: repeat(3, auto);
    text-transform: uppercase;
    grid-gap: 2em;
    &-start{
      grid-column-start: 2;
    }
    &__header{
      background: url("../assets/category.png") no-repeat;
      background-size: 100% 100%;
      padding: 1em 2em;
      color: yellow;
      margin: 0 2em 2em;
      &s{
        display: flex;
        justify-content: center;
      }
    }
    &__radio{
      display: flex;
      align-items: center;
      background: url("../assets/radio.png") no-repeat;
      background-size: 100% 100%;
      padding: 0.5em 2em;
      color: yellow;
      transition: ease filter 0.3s;
      filter: sepia(100%);
      cursor: pointer;
      margin: 0 1em 1em;
      img{
        margin-right: 1em;
      }
      &:hover{
        filter: sepia(0) contrast(200%);
      }
      &:last-of-type{
        margin-bottom: 1em;
      }
    }
    &__choosers{
      display: flex;

    }
  }
  input{
    display: none;
    &:checked{
      &+label{
        filter: sepia(0);
      }
    }
  }
  .btn{
    padding: 2em;
    background: url("../assets/button.png") no-repeat;
    background-size: 100% 100%;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    color: yellow;
    font-size: 16px;
    &:hover{
      filter: contrast(200%);
    }
  }

  @media (max-width: 720px) {
    .pvp{
      width: 100%;
      display: block;
      box-sizing: border-box;
      margin: 1em;
      &__header{
        margin-bottom: 0.5em;
      }
      &__column{
        margin-bottom: 2em;
      }
    }
  }
</style>

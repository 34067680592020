import UnityWebgl from 'unity-webgl';

const Unity = new UnityWebgl({
  loaderUrl: '/unity_client/Build/v0_40.loader.js',
  dataUrl: "/unity_client/Build/v0_40.data",
  frameworkUrl: "/unity_client/Build/v0_40.framework.js",
  codeUrl: "/unity_client/Build/v0_40.wasm",
});


export default Unity;

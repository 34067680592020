<script>
export default {
  name: "LeaderBoard",
  async mounted() {
    await this.$store.dispatch('GET_USERS', this)
  }
}

</script>

<template>
  <div class="lb">
    <table>
      <thead>
        <tr>
          <td>
            Username
          </td>
          <td style="text-align: center">
            Victory
          </td>
          <td style="text-align: center">
            Lose
          </td>
          <td style="text-align: center">
            Energy collected
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in this.$store.state.pvp.users"
          :key="user.id"
        >
          <td>
            {{ user.username }}
          </td>
          <td style="text-align: center">
            {{ user.wins }}
          </td>
          <td style="text-align: center">
            {{ user.loses }}
          </td>
          <td style="text-align: center">
            {{ user.energy }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
.lb{
  margin-top: 1em;
  max-height: 200px;
  overflow-y: scroll;
}
table{
  width: 100% ;
  td{
    color: yellow;
    text-align: left;
    padding: 5px;
  }
  thead{
    position: sticky;
    top: 0;
    background: #000;
    td{
      font-weight: 600;
      color: #fff;
      border-bottom: 2px solid yellow;
    }
  }
  tbody{
    td{
      width: 25%;
      border-bottom: 1px solid #ccc;
    }
  }
}

</style>
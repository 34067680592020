import {createStore} from 'vuex'
import auth from './modules/auth'
import pvp from './modules/pvp'

const store = createStore({
  state() {
    return {
      info: null
    }
  },
  modules: {
    auth,
    pvp
  }
})

export default store

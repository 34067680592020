<template>
  <div class="card-landscape profile">
    <div class="profile__face">
      <div class="profile__photo">
        <img v-if="profile.avatar" :src="profile.avatar" alt="">
        <img v-else :src='thumb' alt="">
      </div>
      <div class="profile__username">
        {{ profile.username }}
      </div>
      <div class="profile__wallet">
        connect wallet
      </div>
    </div>
    <div class="profile__rating">
      rating
    </div>
    <div class="profile__energy">
      energy
    </div>
    <div class="profile__statistic">
      <div class="profile__statistic__title">
        Player statistic
      </div>
      <div>
        Total games: {{ profile.wins + profile.loses }}
      </div>
      <div>
        Wins: {{ profile.wins }}
      </div>
      <div>
        Defeats: {{ profile.loses }}
      </div>
      <div>
        Total energy received: {{ profile.energy }}
      </div>
      <div>
        Total energy sold:
      </div>
      <div>
        Inflation rate:
      </div>
    </div>
    <div class="profile__nft">
      NFT warriors
    </div>
    <div class="btn" v-if="false">Save</div>
  </div>
</template>

<script>
import thumb from "../assets/ZombElf06.jpg"

export default {
  name: "PageProfile",
  data: function () {
    return {
      thumb: thumb
    }
  },
  computed: {
    profile(){
      return this.$store.state.auth;
    }
  }
}
</script>

<style scoped lang="scss">
  .profile{
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 50px repeat(3, auto);
    color: yellow;
    &__face{
      max-width: 200px;
      grid-row-start: 1;
      grid-row-end: 3;
      background: url("../assets/chain.png") repeat-y 15%, url("../assets/chain.png") repeat-y 85%;
      background-size: 15px;
      margin-top: 2em;
    }
    &__rating{
      grid-column-start: 0;
      background: url("../assets/category.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 0 1.5em;
      text-align: left;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
    &__energy{
      grid-column-start: 0;
      background: url("../assets/category.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 0 1.5em;
      text-align: left;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
    &__statistic{
      grid-column-start: 2;
      grid-column-end: 4;
      background: url("../assets/profile_border.png") no-repeat, url("../assets/profiletile.jpg");
      background-size: 100% 100%;
      color: #000;
      padding: 1em 2em;
      text-align: left;
      line-height: 2.3;
      &__title{
        text-align: center;
      }
    }
    &__nft{
      grid-column-start: 1;
      grid-column-end: 4;
      background: url("../assets/profile_border.png") no-repeat, url("../assets/profiletile.jpg");
      background-size: 100% 100%;
      padding: 1em;
      height: 200px;
      color: #000;
    }
    .btn{
      grid-column-start: 2;
      grid-column-end: 2;
      background: url("../assets/category.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 0 1.5em;
      height: 50px;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-self: center;
      text-transform: uppercase;
    }
    &__photo{
      height: 200px;
      width: 200px;
      overflow: hidden;
      border-radius: 100%;
      position: relative;
      top: -2em;
      background: red;
      &:before{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: url("../assets/facering.png") no-repeat;
        background-size: 100% 100%;
      }
      img{
        height: 100%;
        width: 100%;
      }
    }
    &__username{
      background: url("../assets/proflie-card.jpg") no-repeat;
      background-size: 100% 100%;
      padding: 1em;
      text-transform: uppercase;
      margin-bottom: 1em;
      color: #8b8579;
    }
    &__wallet{
      background: url("../assets/proflie-wallet.jpg") no-repeat;
      background-size: 100% 100%;
      padding: 1em;
      text-transform: uppercase;
      color: #f4ac11;
      font-weight: 600;
    }
  }


  @media (max-width: 720px) {
    .profile{
      display: block;
      padding: 1em;
      margin: 1em;
      box-sizing: border-box;
      width: 100%;
      &__face{
        margin: 3em auto 1em;
      }
      &__rating, &__energy{
        padding: 1em 2em;
        margin-bottom: 1em;
      }
      &__statistic{
        margin-bottom: 1em;
      }
      &__nft{
        margin-bottom: 1em;
      }
    }
  }
</style>

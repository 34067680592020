const state = {
  fraction: 'Zombie',
  mode: 'KillKingTimer',
  // hints: 'on',
  status: undefined,
  users: []
};

const getters = {};

const mutations = {
  SET_FRACTION: (state, payload) => {
    state.fraction = payload
  },
  SET_MODE: (state, payload) => {
    state.mode = payload
  },
  // SET_HINTS: (state, payload) => {
  //   state.hints = payload
  // },
  SET_STATUS: (state, payload) => {
    state.status = payload
  },
  SET_USERS: (state, payload) => {
    state.users = payload
  },
  SET_CONFIGS: (state, payload) => {
    state.fraction = payload.fraction
    state.mode = payload.mode
    // state.hints = payload.hints
  }
};

const actions = {
  SAVE_CONFIG: async (context, app) => {
    await app.$http.post(
      '/users/config',
      {
        fraction: state.fraction,
        mode: state.mode,
        // hints: state.hints,
        status: state.status
      }
    )
  },
  SET_STATUS: async (context, status) => {
    context.commit('SET_STATUS', status)
  },
  GET_USERS: async (context, app) => {
    const users = await app.$http.get(
      '/users/',
      state
    )
    context.commit('SET_USERS', users?.data)
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};

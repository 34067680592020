const state = {
  token: undefined,
  jwt: undefined,
  id: undefined,
  authorized: false,
  username: undefined,
  avatar: undefined,
  wins: undefined,
  loses: undefined,
  energy: undefined
};

const getters = {};

const mutations = {
  SET_USER: (state, payload) => {
    state.id = payload.id;
    state.username = payload.username;
    state.wins = payload.wins;
    state.loses = payload.loses;
    state.energy = payload.energy;
    state.avatar = payload.avatar;
    state.jwt = payload.jwt;
    state.authorized = true;
  },
  SET_TOKEN: (state, payload) => {
    state.token = payload
  }
};

const actions = {
  TRY_AUTH: async (context, inst) => {
    await inst.$http.get(
      '/',
      {
        params: {
          token: state.token
        }
      }
    ).then((response) => {
      if(state.token){
        inst.$cookies.set('token', state.token);
      }
      inst.$cookies.set('jwt', response.data.jwt);
      context.commit('SET_USER', response.data);
      context.commit('SET_CONFIGS', response.data.current_config);
      inst.axios.defaults.headers = {
        'Authorization': `bearer ${response.data.jwt}`
      };
    })
  },
  LOGIN: async (context, payload) => {
    await payload.app.$http.post(
      '/login',
      {
        username: payload.payload.username,
        password: payload.payload.password,
      }
    ).then((response) => {
      payload.app.$cookies.set('jwt', response.data.jwt);
      context.commit('SET_USER', response.data);
      context.commit('SET_CONFIGS', response.data.current_config);
    }
    ).catch(() => {
        payload.app.$data.failedLogin = true
    })
  },
  CREATE_ACCOUNT: async (context, payload) => {
    await payload.app.$http.post(
      '/users/',
      {
        username: payload.payload.username,
        email: payload.payload.email,
        password: payload.payload.password,
      }
    ).then((response) => {
        payload.app.$cookies.set('jwt', response.data.jwt);
        context.commit('SET_USER', response.data);
        context.commit('SET_CONFIGS', response.data.current_config);
      }
    ).catch(() => {
      payload.app.$data.failedLogin = true
    })
  },
  SET_TOKEN: async (context, payload) => {
    context.commit('SET_TOKEN', payload);
  },
}


export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <form @submit.prevent="this.doForm">
    <div :class="{active: $data.formType == 'sign', 'f-type': true}" @click.prevent="this.toggleForm('sign')">
      Sign up
    </div>
    <div :class="{active: $data.formType == 'login', 'f-type': true}" @click.prevent="this.toggleForm('login')">
      Login
    </div>
    <label>
      <span>
        Username
      </span>
      <input
          type="text"
          v-model.trim="username"
          required
          :class="{'error': failedLogin}"
          @input="this.$data.failedLogin = false"
      >
    </label>
    <label>
      <span>
        Password
      </span>
      <input
          type="password"
          v-model.trim="password"
          required
          :class="{'error': failedLogin}"
          @input="this.$data.failedLogin = false"
      >
    </label>
    <label v-if="$data.formType == 'sign'">
      <span>
        E-mail
      </span>
      <input type="email" for="email" v-model.trim="email" @blur="validateEmail" :class="{'error': emailError}" >
    </label>
    <div>
      <input type="submit" value="ok">
    </div>
  </form>
</template>

<script>
export default {
  name: "LoginForm",
  data: () => ({
    username: '',
    password: '',
    email: '',
    emailError: false,
    formType: 'login',
    failedLogin: false
  }),
  methods: {
    validateEmail() {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(this.$data.email)) {
        this.$data.emailError = false
      } else {
        this.$data.emailError = true;
      }
    },
    toggleForm(formtype){
      this.$data.formType = formtype
    },
    doForm(){
      if (this.$data.formType == 'login'){
        this.$store.dispatch('LOGIN', {
          payload: this.$data,
          app: this
        })
      }else if(this.$data.formType == 'sign'){
        this.$store.dispatch('CREATE_ACCOUNT', {
          payload: this.$data,
          app: this
        })
      }
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped lang="scss">
  form{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  div.f-type{
    grid-column-start: auto;
    grid-column-end: auto;
    font-size: 18px;
    font-family: inherit;
    border: none;
    background: url("../assets/category.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 1em;
    color: yellow;
    text-transform: uppercase;
    width: 50%;
    margin: 1em auto;
    transition: ease filter 0.3s;
    cursor: pointer;
    &:hover{
      font-weight: 600;
    }
    &.active{
      filter: contrast(150%);
      color: #fff;
      cursor: default;
      &:hover{
        font-weight: 400;
      }
    }
  }
  label, div{
    grid-column-start: 1;
    grid-column-end: -1;
    width: 100%;
  }
  label{
    justify-content: space-between;
    margin-bottom: 1em;
    font-size: 24px;
    display: flex;
    &>*{
      width: 300px;
      padding: 0.5em 1em;
      display: block;
      box-sizing: border-box;
      flex-shrink: 0;
      background: url("../assets/inputbg.jpg") ;
      background-size: 100% 100%;
      border: 0;
      color: #fff;
    }
    input{
      font-size: 16px;
      text-align: center;
      &.error{
        outline: 5px solid red;
        outline-offset: -5px;
      }
      &:focus, &:active {
        outline: none;
      }
    }
  }
  input{
    &[type='submit']{
      font-size: 16px;
      font-family: inherit;
      border: none;
      background: url("../assets/category.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 1em 3em;
      color: yellow;
      text-transform: uppercase;
      margin: 1em auto;
      transition: ease filter 0.3s;
      cursor: pointer;
      &:hover{
        filter: contrast(150%);
      }
    }
  }

  @media (max-width: 720px) {
    form{
      display: block;
      label{
        flex-wrap: wrap;
        margin-bottom: 0.5em;
        &>*{
          width: 100%;
        }
        input{
          display: block;
          padding: 1em;
        }
      }
    }
    div{
      &.f-type{
        display: inline-block;
        margin: 0;
        margin-bottom: 1em;
      }
    }
  }
</style>
